/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Image from "../../../components/img";
import Gist from "../../../components/gistembed";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    a: "a",
    strong: "strong",
    code: "code",
    ul: "ul",
    li: "li",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, "Project Overview"), "\n", React.createElement(_components.p, null, "TransitLink has a $128 ", React.createElement(_components.a, {
    href: "https://www.transitlink.com.sg/adult-monthly-travel-card/"
  }, "Adult Monthly Travel Pass"), " for unlimited travel on Singapore's public transportation system. To find out if purchasing this pass makes sense, it's typical to try checking one's public transport expenditure on TransitLink's fare tracking app (SimplyGo)."), "\n", React.createElement(_components.p, null, "Unfortunately, neither the mobile nor web app provide sums of transport fees within a given period. So users would need to pull out a calculator to run the math themselves. A friend of mine mentioned this frustration, and I decided to build a simple tool that would assist with this task."), "\n", React.createElement(_components.h3, null, "Key Challenges and Learning Points"), "\n", React.createElement(_components.p, null, "Originally, I had wanted to build a separate web app that would support user login, setting of date range, and fetching of user information. I thought of building a separate platform that could act as a dashboard with more useful/fun features (e.g. charting transport fare, visualising users' most common routes and favourite stops, etc ). However this posed many challenges."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "1. Transitlink's web app is ASP.NET based and server-side rendered")), "\n", React.createElement(_components.p, null, "Studying TransitLink's APIs through the Chrome Network tab, I quickly noticed that responses returned HTML text, rather than JSON data. This was initially tricky to work with, as I needed to find targetable patterns that would allow me to extract useful data via regex. The fares I was interested in summing were consistently wrapped in ", React.createElement(_components.code, null, "<td class=\"col3\">$ x.xx </td>"), " tags. Using ", React.createElement(_components.code, null, "regex.match()"), ", I was able to generate an array of fares to sum."), "\n", React.createElement(Image, {
    src: "https://res.cloudinary.com/kiittsunne/image/upload/v1666505347/website/simplygopal_tomo.png",
    maxWidth: "300px"
  }), "\n", React.createElement(_components.p, null, "Having thought that I had cleared this hurdle, I set about trying to understand the architecture of TransitLink's login/ data request APIs. This is where problem #2 showed up."), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "2. HTTPOnly Cookies")), "\n", React.createElement(_components.p, null, "TransitLink relies on a set of 3-4 tokens (they've added 2 since I last tinkered in Sept 2022 👀) for authentication/ authorisation."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "ASP.NET Session Token")), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "__RequestVerificationToken")), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "AuthToken")), "\n"), "\n", React.createElement(Image, {
    src: "https://res.cloudinary.com/kiittsunne/image/upload/v1666506092/website/simplygopal_cookies.png",
    maxWidth: "750px"
  }), "\n", React.createElement(_components.p, null, "Each of these are HTTPOnly Cookies, set by the API response header. After many hours of researching how HTTPOnly Cookies work, and attempting several methods (including HTTPS/proxy tunnelling) to see if it was possible to call TransitLink's APIs through a NodeJS server in VSCode, I still wasn't able to work around the various security measures in place and began feeling worried regarding the legality of what I was doing. So I decided to change approach instead."), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "3. Adjusting Goals")), "\n", React.createElement(_components.p, null, "Since I had been able to calculate the total from the TransitLink API's ", React.createElement(_components.code, null, "html text"), " response, I realised that I might be able to use a Chrome Extension to read the contents of the webpages in-browser, and trigger some kind of pop-up with the useful data instead. This would eliminate the need for probing TransitLink's security measures, whilst still being able to address core user frustrations."), "\n", React.createElement(_components.p, null, "I was able to locate an example Chrome Extension template by ", React.createElement(_components.a, {
    href: "https://gist.github.com/danharper/8364399"
  }, "Dan Harper"), " and adapt it to the final product below."), "\n", React.createElement("br"), "\n", React.createElement(_components.h3, null, "Thoughts on Chrome Manifest V3"), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Context: I'm mainly going to talk about YouTube in this section b/c I don't use any social media other than YT")), "\n", React.createElement(_components.p, null, "In the process of deploying the extension, I received a warning message in-browser that SimplyGoPal was on Chrome Manifest V2 which would no longer be supported in 2023. I was advised to migrate to Chrome Manifest V3 (MV3)."), "\n", React.createElement(_components.p, null, "I had last heard of MV3 in ", React.createElement(_components.a, {
    href: "https://www.wired.com/story/google-chrome-ad-blockers-extensions-api/"
  }, "2019"), ", and looked up more recent coverage of this change via the ", React.createElement(_components.a, {
    href: "https://www.eff.org/deeplinks/2021/12/chrome-users-beware-manifest-v3-deceitful-and-threatening"
  }, "Electronic Frontier Foundation"), " and ", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=18VM1xZQdXc&t=3s"
  }, "Mental Outlaw"), " on YouTube."), "\n", React.createElement(_components.p, null, "As a long-time user of Youtube Vanced, UBlock Origin, Ghostery, and increasingly Mozilla products (Firefox, Mozilla VPN), the growing shadow that MV3 casts is threatening. I'm still unsure whether this shift spells a definitive end for ad and tracker blockers - where there's a will there's a way - on Chrome. But Google's control over so many major pieces of digital infrastracture affords them gross flex rights on other companies and developers actually working to make the Internet safer, faster, and more private."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2022/3/13/22975890/youtube-vanced-app-discontinued-shutting-down-legal-reasons"
  }, "The shutdown of YouTube Vanced"), ", notable spikes in ", React.createElement(_components.a, {
    href: "https://9to5google.com/2022/09/16/youtube-ads-unskippable/"
  }, "the number of unskippable ads"), " on videos, and the ascendence of TikTok to the content format/algorithm meta (", React.createElement(_components.code, null, "pun?.intended"), ") raise questions in my mind."), "\n", React.createElement(_components.p, null, "Is a simple profit motive behind the crackdown on ad/tracker-blocking software, or are there other considerations at play?"), "\n", React.createElement(_components.p, null, "Is the tiktokification of everything 🙄 going to punish creators who don't wish to make content in that format?"), "\n", React.createElement(_components.p, null, "How can Google alternatives like Firefox/ Brave leverage the concerns around/ impact of MV3 to carve out greater mind/market share, and maybe function as a stronger counterbalance."), "\n", React.createElement(_components.p, null, "This said, I'm quite sure the UXers and devs at Google have intensely studied the level of irritation (ads) and fear (privacy) that users are willing to put up with before hunting for alternatives, and the changes will fly beneath these thresholds. I also think that Ad blockers/ VPN users still represent a a minority of those on the Internet - so there might not be significant outrage. But at least on a personal level, I feel worried enough that I'm likely to migrate to Firefox browser."), "\n", React.createElement(_components.p, null, "Ultimately, this little project was eye-opening in terms of connecting my experience as a developer to the broader context of issues regarding/ surrounding technology, and let me explore security tools like the use of HTTPOnly Cookies."), "\n", React.createElement("br"), "\n", " ", "\n", React.createElement(_components.h3, null, "Embedded Gist [ ", React.createElement(_components.a, {
    href: "https://gist.github.com/kiittsunne/ff4a5b6faba000fff4fbbd02c4d640d6/archive/b0ae015f4002d9d8ba062da6ed7fbbe9493400af.zip"
  }, "Download the extension here"), " ]"), "\n", React.createElement(Gist));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
